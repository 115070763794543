/* Global Styles */
:root {
  --primary-color: #039be5;
  --primary-light: #e1f5fe;
  --dark-color: #333333;
  --light-color: #f4f4f4;
  --danger-color: #f44336;
  --success-color: #4caf50;
  --orange-color: #ff9100;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* body {
  line-height: 1.6;
  background-color: white;
  font-weight: 300;
  color: #333;
}

td {
  border-radius: 0;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

img {
  width: 100%;
} */

/* Text Styles*/
.x-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--primary-color);
}

.text-dark {
  color: var(--dark-color);
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.header-text {
  font-size: 30px;
}

/* Cards */
/* .card {
  padding: 1rem;
  border: #ccc 1px solid;
  margin: 0.7rem 0;
}

/* List */
.list {
  margin: 0.5rem 0;
}

.list li {
  padding-bottom: 0.3rem;
}
*/

/* Padding */
.p {
  padding: 0.5rem;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}
.py-4 {
  padding: 4rem 0;
}
.py-5 {
  padding: 5rem 0;
}
.px {
  padding: 0 0.5rem;
}
.px-1 {
  padding: 0 1rem;
}
.px-2 {
  padding: 0 2rem;
}
.px-3 {
  padding: 0 3rem;
}
.px-4 {
  padding: 0 4rem;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}
.mx {
  margin: 0 0.5rem;
}
.mx-1 {
  margin: 0 1rem;
}
.mx-2 {
  margin: 0 2rem;
}
.mx-3 {
  margin: 0 3rem;
}
.mx-4 {
  margin: 0 4rem;
}
.mx-5 {
  margin: 0 5rem;
}

/* Grid */
.row > .col {
  padding: 0;
}
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2rem;
}

.grid-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 2rem;
}

.btn-link {
  background: none;
  padding: 0;
  margin: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-small {
  margin-right: 0.2rem;
}

.badge {
  font-size: 0.8rem;
  padding: 0.2rem 0.7rem;
  text-align: center;
  margin: 0.3rem;
  background: var(--light-color);
  color: #333;
  border-radius: 5px;
}

.alert {
  padding: 0.7rem;
  margin: 1rem 0;
  opacity: 0.9;
  background: var(--light-color);
  color: #333;
}

.btn-primary,
.bg-primary,
.badge-primary,
.alert-primary {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}

.btn-primary-light,
.bg-primary-light,
.badge-primary-light,
.alert-primary-light {
  background-color: var(--primary-light) !important;
  color: #fff !important;
}

.btn-light,
.bg-light,
.badge-light,
.alert-light {
  background: var(--light-color) !important;
  color: #333;
}

.btn-dark,
.bg-dark,
.badge-dark,
.alert-dark {
  background: var(--dark-color) !important;
  color: #fff;
}

.btn-danger,
.bg-danger,
.badge-danger,
.alert-danger {
  background: var(--danger-color) !important;
  color: #fff;
}

.btn-success,
.bg-success,
.badge-success,
.alert-success {
  background: var(--success-color) !important;
  color: #fff !important;
}

.btn-orange,
.bg-orange,
.badge-orange,
.alert-orange {
  background: var(--orange-color) !important;
  color: #fff;
}

.btn-white,
.bg-white,
.badge-white,
.alert-white {
  background: #fff !important;
  color: #333;
  border: #ccc solid 1px;
}

.btn-primary:hover {
  background-color: var(--primary-color);
  opacity: 0.8;
}

.btn-danger:hover {
  background-color: var(--danger-color);
  opacity: 0.8;
}

.btn-dark:hover {
  background-color: var(--dark-color);
  opacity: 0.8;
}

.bg-light,
.badge-light {
  border: #ccc solid 1px;
}

.round-img {
  border-radius: 50%;
}

/* Nav Bar */
nav .brand-logo {
  /* padding: 0 15px; */
  font-size: 1.3rem;
}
nav .sidenav-trigger {
  margin: 0;
}

/* Forms */
input {
  margin: 1.2rem 0;
}

.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}

input[type='radio']:checked + span:after {
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

/* input[type='text'],
input[type='email'],
input[type='password'],
input[type='date'],
select,
textarea {
  display: block;
  width: 100%;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
} */

/* .MuiInput-underline:before,
.MuiInput-underline:after {
  border-bottom: 0px !important;
} */

input[type='submit'],
button {
  font: inherit;
}

.form-group {
  margin: 1.2rem 0;
}

.form-group input {
  margin: 0.2rem 0;
}

.form-container {
  max-width: 500px;
  margin: 2rem auto;
  overflow: hidden;
  padding: 0 2rem;
}

/* .form-container h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
} */

table th,
table td {
  padding: 1rem;
  text-align: left;
}

table th {
  background: var(--light-color);
}

/* Animation (Add After) */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 1000ms ease-in;
}

/* Container Backgrounds */
.landing-background-1 {
  background-image: linear-gradient(
      rgba(189, 189, 189, 0.9),
      rgba(189, 189, 189, 0.9)
    ),
    url(./img/warehouse-worker.jpg);
}

/* Mobile Styles */
@media (max-width: 700px) {
  .hide-sm {
    display: none;
  }

  .grid-2,
  .grid-3,
  .grid-4 {
    grid-template-columns: 1fr;
  }

  /* Text Styles */
  .x-large {
    font-size: 3rem;
  }

  .large {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }
}
